@charset "utf-8";

// Dimensions
$max-content-width: 1200px;

@import
  "variables",
  "themes",
  "layout",
  "base",
  "distill"
;

.name-with-logo {
  display: flex;
  align-items: center; /* This will vertically center the logo with your name */
}

.logo-img {
  margin-right: 15px; /* Adjust this value as per your design preferences */
  height: 50px; /* You can adjust this value based on the size you want for your logo */
  width: auto;
}
